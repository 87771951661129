<template>
  <div>
    <car-master-status-popup-car-status
      :temp-search-car-status="tempSearchCarStatus"
      :is-popup-car-status-active.sync="isPopupCarStatusActive"
      @select-item="fncSelectCarStatus"
    />
    <car-master-status-popup-car-location
      :temp-search-car-location="tempSearchCarLocation"
      :is-popup-car-location-active.sync="isPopupCarLocationActive"
      @select-item="fncSelectCarLocation"
    />
    <b-modal
      ref="refModalDatePickerStatusDate"
      centered
      title="วันที่"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="statusDate"
        class="mt-6"
        locale="th"
        @input="refModalDatePickerStatusDate.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalErrorMessage"
      ok-only
      ok-variant="danger"
      ok-title="ตกลง"
      modal-class="modal-danger"
      centered
      title="กรุณาแก้ไขข้อมูล"
    />
    <b-card
      :title="`ปรับปรุงสถานะรถยนต์ ${resolveFormType == 'edit' ? 'update' : ''}`"
    >
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="RegNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขทะเบียน
              </div>
            </template>
            <b-input-group>
              <b-form-input
                id="RegNum1"
                v-model="inputRegNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                disabled
              />
              <b-form-input
                id="RegNum2"
                v-model="inputRegNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;  margin-left: 5px;"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="พอร์ต"
            label-for="portCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-form-input
              id="portCode"
              v-model="inputPortCode"
              placeholder="พอร์ต"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อพอร์ต"
            label-for="portDesc"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อพอร์ต
              </div>
            </template>
            <b-form-input
              id="portDesc"
              v-model="inputPortDesc"
              placeholder="ชื่อพอร์ต"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเลขตัวถัง"
            label-for="chassisNumber"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเลขตัวถัง
              </div>
            </template>
            <b-form-input
              id="chassisNumber"
              v-model="inputChassisNumber"
              placeholder="หมายเลขตัวถัง"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="สหกรณ์"
            label-for="cooperateCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สหกรณ์
              </div>
            </template>
            <b-form-input
              id="cooperateCode"
              v-model="inputCooperateCode"
              placeholder="สหกรณ์"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="อู่จอดรถ"
            label-for="carLocationOld"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                อู่จอดรถ
              </div>
            </template>
            <b-form-input
              id="carLocationOld"
              v-model="inputCarLocationOld"
              placeholder="อู่จอดรถ"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อลูกค้า"
            label-for="cusName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อลูกค้า
              </div>
            </template>
            <b-form-input
              id="cusName"
              v-model="inputCusName"
              placeholder="ชื่อลูกค้า"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="สถานะปัจจุบัน"
            label-for="carStatus"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สถานะปัจจุบัน
              </div>
            </template>
            <b-form-input
              id="carStatus"
              v-model="inputCarStatus"
              placeholder="สถานะปัจจุบัน"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="สถานะปัจจุบัน"
            label-for="carStatusDesc"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สถานะปัจจุบัน
              </div>
            </template>
            <b-form-input
              id="carStatusDesc"
              v-model="inputCarStatusDesc"
              placeholder="สถานะปัจจุบัน"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="12">
          <hr>
          <h3>สถานะใหม่</h3>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="CarStatusCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สถานะใหม่
              </div>
            </template>
            <b-input-group v-show="!CarStatusCode">
              <b-form-input
                id="CarStatusCode"
                v-model="inputSearchCarStatus"
                placeholder="สถานะใหม่"
                trim
                @keydown.enter.native="
                  fncSearchCarStatus(inputSearchCarStatus)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarStatus()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="CarStatusCode">
              <b-form-input
                id="CarStatusCode"
                :value="inputCarStatusName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarStatus()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="validateRequireStatus"
              class="text-danger"
            >
              กรุณาเลือกสถานะใหม่
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่
              </div>
            </template>
            <b-form-input
              id="inputStatusDate"
              :value="resolveFormatDate(statusDate)"
              trim
              readonly
              placeholder="วันที่"
              @click="fncShowDatepickerStatusDate()"
            />
            <small
              v-if="validateRequireDate"
              class="text-danger"
            >
              กรุณาเลือกวันที่
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเหตุ"
            label-for="ReMark"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเหตุ
              </div>
            </template>
            <b-form-input
              id="ReMark"
              v-model="inputReMark"
              placeholder="หมายเหตุ"
              trim
              autocomplete="off"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="carLocation"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สถานที่เก็บรถ
              </div>
            </template>
            <b-input-group v-show="!carLocation">
              <b-form-input
                id="carLocation"
                v-model="inputSearchCarLocation"
                placeholder="ค้นหาสถานที่เก็บรถ"
                trim
                @keydown.enter.native="
                  fncSearchCarLocation(inputSearchCarLocation)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupCarLocation()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="carLocation">
              <b-form-input
                id="carLocation"
                :value="carLocation"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelCarLocation()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-overlay
              :show="overlaySaveButton"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button

                variant="primary"
                @click="fncClickSubmit()"
              >
                Submit
              </b-button>
            </b-overlay>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              :to="{ name: 'car-master-status' }"
            >
              ยกเลิก
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BInputGroupAppend, BButton, BModal, BOverlay,
} from 'bootstrap-vue'
import {
  onUnmounted, ref, computed,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import store from '@/store'
import router from '@/router'
import {
  VDatePicker,
} from 'vuetify/lib'
import { useToast } from 'vue-toastification/composition'
import CarMasterStatusPopupCarStatus from './car-master-status-popup/CarMasterStatusPopupCarStatus.vue'
import CarMasterStatusPopupCarLocation from './car-master-status-popup/CarMasterStatusPopupCarLocation.vue'
import CarMasterStatusStoreModule from './CarMasterStatusStoreModule'
// eslint-disable-next-line import/order
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BModal,
    BOverlay,
    VDatePicker,

    CarMasterStatusPopupCarStatus,
    CarMasterStatusPopupCarLocation,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appCarMasterStatus'
    const toast = useToast()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, CarMasterStatusStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // ประกาศตัวแปร Ref
    const refModalDatePickerStatusDate = ref(null)
    const refModalDatePickerGPSDateStart = ref(null)

    // ประกาศตัวแปร Textbox
    const inputSearchBranch = ref(null)
    const inputSearchCarStatus = ref(null)
    const inputChassisNumber = ref(null)
    const inputRegNum1 = ref(null)
    const inputRegNum2 = ref(null)
    const inputPortDesc = ref(null)
    const inputPortCode = ref(null)
    const inputCarStatus = ref(null)
    const inputCusName = ref(null)
    const inputCooperateCode = ref(null)
    const inputCarLocation = ref(null)
    const inputCarStatusName = ref(null)
    const inputReMark = ref(null)
    const inputSearchCarLocation = ref(null)
    const inputCarLocationOld = ref(null)
    const inputCarStatusDesc = ref(null)
    const errorMessage = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const username = ref(null)
    const cpncod = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const CarStatusCode = ref(null)
    const CarStatusName = ref(null)
    const CarStatus = ref(null)
    const ReMark = ref(null)
    const carLocation = ref(null)
    const statusDate = ref(null)
    const carLocationOld = ref(null)

    const tempSearchBranch = ref(null)
    const tempSearchCarStatus = ref(null)
    const tempSearchCarLocation = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupBranchActive = ref(false)
    const isPopupCarStatusActive = ref(false)
    const isPopupCarLocationActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySaveButton = ref(false)
    const overlaySubmitButton = ref(false)
    const refModalErrorMessage = ref(null)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    const resolveFormType = computed(() => {
      let setFormType = ''
      if (router.currentRoute.name === 'car-master-status-form-update') {
        setFormType = 'edit'
      }
      return setFormType
    })

    const getRegnum1 = router.currentRoute.params.regnum1
    const getRegnum2 = router.currentRoute.params.regnum2
    if (getRegnum1 && getRegnum2) {
      console.log(getRegnum1 && getRegnum2)
      const payload = {
        action: 'EDIT',
        events: 'UPD_STATUS',
        function: 'GET',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        cpncod_in: null,
        cpnbrncod: null,
        chassis_number_in: null,
        regnum1_in: getRegnum1,
        regnum2_in: getRegnum2,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_MASTER`, payload)
        .then(response => {
          console.log(response.data.responseData)
          inputRegNum1.value = response.data.responseData[0].regnum1
          inputRegNum2.value = response.data.responseData[0].regnum2
          inputPortCode.value = response.data.responseData[0].port_code
          inputPortDesc.value = response.data.responseData[0].port_desc
          inputChassisNumber.value = response.data.responseData[0].chassis_number
          inputCooperateCode.value = response.data.responseData[0].cooperate_code
          inputCarStatus.value = response.data.responseData[0].car_status
          inputCusName.value = response.data.responseData[0].accnamtha
          inputCarLocationOld.value = response.data.responseData[0].car_location
          inputCarStatusDesc.value = response.data.responseData[0].car_status_desc
          // inputIdStrDate.value = moment(response.data.responseData[0].psnregsin).format('YYYY-MM-DD')
        })
    }

    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupCarStatus = search => {
      if (search) tempSearchCarStatus.value = search
      else tempSearchCarStatus.value = null

      isPopupCarStatusActive.value = true
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchCarStatus = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          username: null,
          CAR_STATUS_IN: inputCarStatus.value,
        }
        console.log(payload, 'searchcarstatus')

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_GET_CAR_STATUS`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              CarStatusCode.value = getSearchResult[0].car_status_new
              CarStatusName.value = getSearchResult[0].status_desc_new
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarStatus(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelCarStatus = () => {
      CarStatusCode.value = null
      inputCarStatusName.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectCarStatus = data => {
      CarStatusCode.value = data.car_status_new
      inputCarStatusName.value = data.status_desc_new
    }

    /* ฟังก์ชั่นเปิด Popup สถานที่เก็บรถ */
    const fncOpenPopupCarLocation = search => {
      if (search) tempSearchCarLocation.value = search
      else tempSearchCarLocation.value = null

      isPopupCarLocationActive.value = true
    }

    /* ฟังก์ชั่นค้นหาสถานที่เก็บรถ */
    const fncSearchCarLocation = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'CAR_LOCATION',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              carLocation.value = getSearchResult[0].tabkeytwo
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupCarLocation(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกสถานที่เก็บรถ */
    const fncCancelCarLocation = () => {
      carLocation.value = null
      inputSearchCarLocation.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกสถานที่เก็บรถจาก Popup */
    const fncSelectCarLocation = data => {
      carLocation.value = data.tabkeytwo
    }

    // /* ฟังก์ชั่นเมื่อมีการกดเลือกเลขทะเบียนจาก Popup */
    // const fncSelectRegNum = data => {
    //   regNum1.value = data.regnum1
    //   regNum2.value = data.regnum2
    //   InputchassisNum.value = data.chassis_num
    // }

    /* ฟังก์ชั่นแสดง DatePicker วันที่จดทะเบียน */
    const fncShowDatepickerStatusDate = () => {
      refModalDatePickerStatusDate.value.show()
    }
    const onSubmitStatus = ref(false)

    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    const validateRequireStatus = computed(() => {
      if (!CarStatusCode.value && onSubmitStatus.value) return true
      return false
    })

    const validateRequireDate = computed(() => {
      if (!statusDate.value && onSubmitStatus.value) return true
      return false
    })
    /* ฟังก์ชั่นเมื่อกดปุ่ม Submit */
    // eslint-disable-next-line consistent-return
    const fncClickSubmit = () => {
      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)
      onSubmitStatus.value = true
      if (validateRequireStatus.value) return false
      onSubmitStatus.value = false

      onSubmitStatus.value = true
      if (validateRequireDate.value) return false
      onSubmitStatus.value = false

      const payload = {

        action: 'EDIT',
        events: 'UPD_STATUS',
        function: 'SUBMIT',
        username: JSON.parse(localStorage.getItem('userData')).member_no,
        CPNCOD_IN: '0001',
        cpnbrncod_in: inputPortCode.value,
        REGNUM1_IN: inputRegNum1.value,
        REGNUM2_IN: inputRegNum2.value,
        NEW_STATUS_IN: CarStatusCode.value,
        STATUS_DATE_IN: statusDate.value,
        REMARK_IN: inputReMark.value,
        CAR_LOCATION_NEW_IN: carLocation.value,

      }
      console.log('submit', payload)

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_MASTER`, payload)
        .then(response => {
          if (response.data.responseData[0].success === 'ERROR') {
            errorMessage.value = response.data.responseData[0].error_message
            console.log('erroradd')
            refModalErrorMessage.value.show()
          } else {
            errorMessage.value = null
            toast({
              component: ToastificationContent,
              props: {
                title: 'แจ้งเตือน',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'บันทึกข้อมูลสำเร็จ',
              },
            })
          }
          console.log('SP_RT_UPD_CAR_MASTER', response)
          router.push('/car_master_status') // คำสั่ง Redirect
          console.log('add', response)
        })
    }

    /* ========== ส่วนการทำงานแสดงผล UI ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {

      // ประกาศตัวแปร Ref
      refModalDatePickerStatusDate,
      refModalDatePickerGPSDateStart,
      tempSearchCarLocation,

      // ประกาศตัวแปร Textbox
      inputSearchBranch,
      inputChassisNumber,
      inputRegNum1,
      inputRegNum2,
      inputPortDesc,
      inputPortCode,
      inputCarStatus,
      inputCusName,
      inputCooperateCode,
      inputSearchCarStatus,
      inputCarLocation,
      inputCarStatusName,
      inputReMark,
      inputCarLocationOld,
      inputSearchCarLocation,
      inputCarStatusDesc,

      // ประกาศตัวแปรสำหรับเก็บค่า
      username,
      cpncod,
      branchCode,
      branchName,
      tempSearchBranch,
      tempSearchCarStatus,
      statusDate,
      CarStatus,
      CarStatusCode,
      CarStatusName,
      ReMark,
      carLocationOld,
      carLocation,
      errorMessage,

      // ประกาศตัวแปร Active / Inactive
      isPopupBranchActive,
      isPopupCarStatusActive,
      isPopupCarLocationActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySaveButton,
      overlaySubmitButton,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncOpenPopupCarStatus,
      fncSearchCarStatus,
      fncCancelCarStatus,
      fncSelectCarStatus,
      fncShowDatepickerStatusDate,
      fncSearchCarLocation,
      fncOpenPopupCarLocation,
      fncCancelCarLocation,
      fncSelectCarLocation,
      fncClickSubmit,

      // ส่วนการทำงานแสดงผล UI
      resolveFormatDate,
      resolveFormType,
      refModalErrorMessage,

      validateRequireStatus,
      validateRequireDate,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* for local only */
/* .delete-margin {
  margin-bottom: -60px !important;
} */
</style>
